import React from "react";
import "./index.scss";

const Contacts = () => {
  return (
    <section className="content__section show-2">
      <div className="contacts">
        <div className="content__wrapper">
          <h3>Свяжись с нами!</h3>
          <strong>Адрес:</strong> г. Пермь, ул. Попова, д. 16
          <br />
          <strong>WhatsApp:</strong> +7(952)33-69-829
          <br /> <strong>Telegram:</strong>{" "}
          <a href="https://t.me/muhomori">Телеграм - чат</a>
          <br /> <strong>Tel:</strong>{" "}
          <a href="tel:+79523369923">+7(952)33-69-829</a>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2113.787877450685!2d56.226029316114065!3d58.00835992326267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43e8c725aac45657%3A0xc21e09934d524a5f!2z0YPQuy4g0J_QvtC_0L7QstCwLCAxNiwg0J_QtdGA0LzRjCwg0J_QtdGA0LzRgdC60LjQuSDQutGA0LDQuSwgNjE0MDQ1!5e0!3m2!1sru!2sru!4v1577289209279!5m2!1sru!2sru"
          width="100%"
          height="450px"
          frameBorder="0px"
          allowFullScreen=""></iframe>
      </div>
    </section>
  );
};

export default Contacts;
