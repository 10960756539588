import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { AddressSuggestions } from "react-dadata";
import { isMobilePhone } from "validator";
import App from "../app";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidPhone: false,
      isValidAdress: false,
    };
    console.log(this);
  }

  CartContent = () => {
    let { order } = this.props;
    let content = [];

    {
      order.forEach((el) => {
        content.push(
          <tr key={el.id} data={el.id} className="itemList">
            <td className="name">{el.name}</td>
            <td className="type">{el.type}</td>
            <td className="price">{el.price}</td>
            <td className="count">
              <input
                type="number"
                className="cart__cont__input"
                onChange={this.props.calculateSumm}
                defaultValue="1"></input>
            </td>
            <td className="itemSumm">{el.price}</td>
          </tr>
        );
      });
    }
    return (
      <table className="cart__table">
        <tbody>
          <tr>
            <td colSpan={5}>
              <div className="cart_header">
                <p>Состав заказа</p>
                <i
                  onClick={this.closeCart}
                  className="far fa-times-circle cart__close"></i>
              </div>
            </td>
          </tr>
          <tr>
            <td>Товар</td>
            <td>Вес</td>
            <td>Цена</td>
            <td>Кол-во</td>
            <td>Сумма</td>
          </tr>
          {content}
          <tr key="total">
            <td colSpan={4}> Общая сумма: </td>
            <td id="totalSumm"></td>
          </tr>
        </tbody>
      </table>
    );
  };

  closeCart = () => {
    this.props.hide(this.props.loader);
    this.props.hide(this.props.cart);
    this.props.show(this.props.cartIcon);
  };

  cashOut = () => {
    let name = document.getElementById("username").value;
    let surname = document.getElementById("surname").value;
    let patronymic = document.getElementById("patronymic").value;
    let phone = this.state.phone;
    let adress = this.state.adress;

    let comment = document.getElementById("comment").value;
    let indextown = document.getElementById("indextown").value;
    let count = [];
    document.querySelectorAll(".count").forEach((elem) => {
      count.push(elem.children[0].value);
    });

    if (name == "" || !this.state.isValidPhone || !this.state.isValidAdress) {
      alert("Заполните все поля формы!");
    } else {
      axios({
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded", "referrerPolicy": "no-referrer-when-downgrade" },
        url: "https://amanita-shop.ru/sendtoicq.php",
        data: {
          name: `${surname} ${name} ${patronymic}`,
          phone: phone,
          adress: adress,
          comment: comment,
          indextown: indextown,
          order: [...this.props.order],
          counted: count,
        },
      })
        .then(() => {
          this.props.show(this.props.success);
          setTimeout(() => {
            this.props.hide(this.props.success);
            this.props.clearCart();
          }, 10000);
        })
        .catch(function (error) {
          console.log("post to sendtelegram error", error);
        });
    }
  };

  checkPhone = (e) => {
    let phone = e.target.value;
    if (isMobilePhone(phone, "ru-RU")) {
      e.target.classList.remove("wrongPhone");
      e.target.classList.add("rightPhone");
      this.setState({
        isValidPhone: true,
        phone: e.target.value,
      });
    } else {
      e.target.classList.remove("rightPhone");
      e.target.classList.add("wrongPhone");
      this.setState({
        isValidPhone: false,
      });
    }
  };

  checkAdress = (e) => {
    let adress = e.target.value;
    console.log(adress);
    if (adress.length > 5) {
      e.target.classList.remove("wrongPhone");
      e.target.classList.add("rightPhone");
      this.setState({
        adress: adress,
        isValidAdress: true,
      });
    } else {
      e.target.classList.remove("rightPhone");
      e.target.classList.add("wrongPhone");
      this.setState({
        adress: adress,
        isValidAdress: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="success">
          <div className="success__text">
            Спасибо за заказ! Мы с вами свяжемся в самое ближайшее время!
          </div>
        </div>
        <div className="cart">
          <div className="cart__wrapper">
            <this.CartContent className="cart__cont" />
            <form className="cart__input_form">
              <p>Поля помеченные звездочкой обязательны к заполнению.</p>
              <input
                type="text"
                className="cart__input"
                id="surname"
                placeholder="Фамилия"
              />
              <input
                type="text"
                className="cart__input"
                id="username"
                placeholder="Имя*"
              />
              <input
                type="text"
                className="cart__input"
                id="patronymic"
                placeholder="Отчество"
              />

              <AddressSuggestions
                inputProps={{
                  className: "cart__input",
                  id: "adress",
                  name: "adress",
                  placeholder: "Адрес*",
                  onChange: this.checkAdress,
                }}
                token="26b6b524ecdd3fd1beb80fbf7e6ca588208d7280"
                count="5"
                minChars={3}
                delay={300}
                onChange={(e) => {
                  this.setState({ adress: e.value });
                }}
              />

              <input
                type="text"
                className="cart__input"
                id="indextown"
                placeholder="Индекс"
              />
              <input
                type="text"
                className="cart__input"
                id="phone"
                name="phone"
                placeholder="+7(999)999-99-99*"
                onChange={this.checkPhone}
              />
              <input
                type="text"
                className="cart__input"
                id="comment"
                placeholder="Комментарий"
              />
            </form>
            <p>
              Стоимость доставки можно рассчитать{" "}
              <a target="_blank" href="https://www.pochta.ru/parcels">
                здесь.
              </a>
            </p>
          </div>
          <div className="cart__footer">
            <button
              className="button clear_cart_btn"
              onClick={this.props.clearCart}>
              Очистить
            </button>
            <button className="button make_order_btn" onClick={this.cashOut}>
              Заказать
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Cart;
