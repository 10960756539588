import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function ShowAllProducts(products, ...props) {
  console.log(products);

  let IsInStock = ({ id, price }) => {
    if (price <= 0) {
      return (
        <>
          <p className="g-item__price">Ждем поступления товара.</p>
          <button
            className="button add_to_cart cursor_not_allowed"
            disabled
            data-id={id}>
            Продано
          </button>
        </>
      );
    } else {
      return (
        <>
          <p className="g-item__price">от {price} руб.</p>
          <button className="button add_to_cart" data-id={id}>
            Выбрать
          </button>
        </>
      );
    }
  };

  return (
    <section className="content__section show-1">
      {products.products.map(({ id, name, price, description, image }) => (
        <Link
          key={id}
          to={`/item/${id}`}
          href={"./src/" + image}
          title={description}>
          <div className="g-item">
            <img
              className="g-item__img"
              src={`../images/gallery/${image}`}
              alt={name}
            />
            <div className="g-item__description">
              <p
                className="g-item__name"
                dangerouslySetInnerHTML={{ __html: name }}></p>
              <IsInStock price={price} id={id}></IsInStock>
            </div>
          </div>
        </Link>
      ))}
    </section>
  );
}

export default ShowAllProducts;
