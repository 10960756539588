import React from 'react';
import './index.scss';

const Footer = () => {
    return(
        <footer className="footer">
            <div className="footer__container">Amanita-shop.ru 2023г. г.Пермь</div>
        </footer>
    )
}

export default Footer;
