import React from 'react'
import './index.scss'
import {Link} from 'react-router-dom'


const Header = (props) => {

    return (
    <header className="header">
        <nav className="header__navmenu">
        <ul className="navmenu__ul menu">
            <li className="navmenu__item"><Link className='navmenu__link' to='/'>Главная</Link></li>
            <li className="navmenu__item"><Link className='navmenu__link' to='/contacts'>Контакты</Link></li>
            <li className="navmenu__item"><Link className='navmenu__link' to='/delivery'>Доставка</Link></li>
            <li className="navmenu__item">
                <a className="navmenu__link" rel="nofollow" target="_blank" href="https://t.me/muhomori">
                    <i className="fa-brands fa-telegram"></i>
                </a>
            </li>
            {/* <li className="navmenu__item">
                <a className="navmenu__link" rel="nofollow" target="_blank" href="https://www.instagram.com/guruhimochkincat">
                    <i className="fa-brands fa-instagram"></i>
                </a>
            </li> */}
        </ul>
        <div className="topmenu-right">
            {/*<ul className="lang">*/}
            {/*    <li className="navmenu__item"><a className='navmenu__link' href="https://amanita-shop.ru">RUS</a></li>*/}
            {/*    <li className="navmenu__item"><a className='navmenu__link' href="https://amanita-shop.ru/en">EN</a></li> */}
            {/*</ul>*/}
            <i onClick={props.openCart} className={props.cartFull ? "fas open_cart fa-cart-arrow-down" : "fas fa-shopping-cart open_cart"}></i>
        </div>
        </nav>
    </header>
    )}

export default Header;
