import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  scrollTo() {
    let offsetToTop =
      document.querySelector(".content").getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({
      top: offsetToTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div className="side-menu">
        <div className="side-menu__logo">
          <Link className="logo__homelink" to="/">
            <h1 className="logo__companyname">Мухоморный Лес 🍄</h1>
          </Link>
          <span className="logo__hint">
            Премиум качество! Доставка по всему миру.
          </span>
        </div>

        <div className="side-menu__navmenu">
          <ul className="side-menu__list menu">
            <li className="side-menu__item">
              <Link onClick={this.scrollTo} className="side-menu__link" to="/">
                Главная
              </Link>
            </li>
            <li className="side-menu__item scroll">
              <Link
                onClick={this.scrollTo}
                className="side-menu__link"
                to="/contacts">
                Контакты
              </Link>
            </li>
            <li className="side-menu__item scroll">
              <Link
                onClick={this.scrollTo}
                className="side-menu__link"
                to="/delivery">
                Условия доставки
              </Link>
            </li>
            <li className="side-menu__item">
              <a
                className="side-menu__link"
                rel="nofollow"
                target="_blank"
                href="https://t.me/s/mukhomor59">
                Отзывы
              </a>
            </li>
            <li className="side-menu__item">
              <a
                className="side-menu__link"
                rel="nofollow"
                target="_blank"
                href="https://t.me/muhomori">
                Телеграм - чат для вопросов
              </a>
            </li>
            {/* <li className="side-menu__item">
                <a className="side-menu__link" rel="nofollow" target="_blank"
                href="https://www.instagram.com/guruhimochkincat">Instagram</a>
            </li> */}
            <li className="side-menu__item scroll">
              <Link
                onClick={this.scrollTo}
                className="side-menu__link"
                to="/about">
                О нашем Грибе!
              </Link>
            </li>
          </ul>
          <a href="#" className="side-menu__link side-menu__toggle-menu">
            <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>
    );
  }
}

// export default Menu;
