import React from "react";
import Header from "../header";
import Menu from "../main-menu";
import Footer from "../footer";
import SectionShop from "../content/section-shop/index";
import Contacts from "../content/contacts/index";
import Delivery from "../content/delivery/index";
import AboutPage from "../content/about";
import Item from "../content/item/index";
import Cart from "../cart/index";
import Disclaimer from "../Disclaimer/Disclaimer";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.scss";
import cartLogo from "./shopping_cart.png";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.refToContent = React.createRef();
    this.state = {
      allOrders: [],
      all_products: [],
      cartFull: false,
      orderCount: 0,
    };
    this.addToOrder = this.addToOrder.bind(this);
    this.loaderRef = React.createRef();
  }

  componentDidMount() {
    axios
      .get("https://amanita-shop.ru/db.php")
      //   .get("http://localhost/db.php")
      .then(({ data }) => {
        this.setState({
          all_products: data,
        });
      });
    this.cart = document.querySelector(".cart");
    this.cartIcon = document.querySelector("#flow_cart");
    this.disclaimer = document.querySelector(".disclaimer");
    this.success = document.querySelector(".success");
    this.loader = document.querySelector(".loader");
  }

  show(elem) {
    elem.style.display = "flex";
  }

  hide(elem) {
    elem.style.display = "none";
  }

  hideDisclamer = () => {
    const disclaimer = document.querySelector(".disclaimer");
    this.hide(disclaimer);
    const loader = document.querySelector(".loader");
    this.hide(loader);
  };

  addToOrder = (item) => {
    this.setState(() => {
      return {
        allOrders: [...item],
      };
    });
    this.state.cartFull = this.state.allOrders.length > 0;
    if (this.state.cartFull) {
      this.show(this.cartIcon);
    } else {
      this.hide(this.cartIcon);
    }
  };

  openCart = (e) => {
    if (this.state.allOrders.length > 0) {
      this.calculateSumm();
      this.show(this.loader);
      console.log(this.cart);
      this.show(this.cart);
      this.hide(this.cartIcon);
    } else {
      e.preventDefault(alert("Корзина пуста!"));
    }
  };

  calculateSumm = (e) => {
    let orderCount = [];
    document.querySelectorAll(".count").forEach((elem) => {
      let count = elem.children[0].value;
      if (count < 0) {
        count = 0;
        elem.children[0].value = 0;
      }
      let price = elem.previousElementSibling.innerHTML;
      let summ = +count * +price;
      elem.nextElementSibling.innerHTML = summ;
    });

    let totalSumm = 0;
    document.querySelectorAll(".itemSumm").forEach((el) => {
      totalSumm += +el.innerText;
      document.getElementById("totalSumm").innerHTML = totalSumm;
    });
  };

  clearCart = () => {
    this.setState((this.state.allOrders = []));
    this.hide(this.loader);
    this.hide(this.cartIcon);
  };

  closeAlert = (e) => {
    this.hide(document.querySelector(".alert"));
  };

  render() {
    return (
      <>
        <Router>
          <div className="body__container">
            <Header openCart={this.openCart} cartFull={this.state.cartFull} />
            <div className="alert">
              При возникновении проблем с заказами, обратитесь в <br />
              <a rel="nofollow" target="_blank" href="https://t.me/muhomori">
                Телеграм - чат.
              </a>
              <div className="closealert" onClick={this.closeAlert}></div>
            </div>
            <div className="wrapper">
              <Menu />
              <div className="content" ref={this.refToContent}>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={(props) => (
                      <SectionShop
                        {...props}
                        products={this.state.all_products}
                      />
                    )}
                  />
                  <Route path="/contacts" component={Contacts} />
                  <Route path="/delivery" component={Delivery} />
                  <Route path="/about" component={AboutPage} />
                  <Route
                    path="/item/:id"
                    render={(props) => (
                      <Item
                        {...props}
                        allOrders={this.state.allOrders}
                        addToOrder={this.addToOrder}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
            <Footer />
          </div>
          <div className="loader" ref="loaderRef">
            <Cart
              clearCart={this.clearCart}
              order={this.state.allOrders}
              calculateSumm={this.calculateSumm}
              orderCount={this.state.orderCount}
              show={this.show}
              hide={this.hide}
              loader={this.loader}
              cart={this.cart}
              cartIcon={this.cartIcon}
              success={this.success}
            />
          </div>
          <Disclaimer hide={this.hide} />
        </Router>
        <img src={cartLogo} id="flow_cart" onClick={this.openCart}></img>
      </>
    );
  }
}

export default App;
