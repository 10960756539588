import React, { Component } from 'react';
import './Disclaimer.scss';

export default class Disclaimer extends Component {
  constructor(props){
    super(props)
    this.hideDisclaimer = this.hideDisclaimer.bind(this)
  }

  hideDisclaimer = () => {
    document.querySelector('.disclaimer').style.display = 'none'
  }

  render (){
    return (
      <div className='disclaimer'>
        <h3>Дисклеймер</h3>
        <div className='text'>
          Все товары, представленные на сайте стоит использовать только в качестве предмета визуального созерцания их красоты созданной природой. 
          Мы настоятельно не рекомендуем приобретать представленные товары. Применение, размещенных товаров на сайте, внутрь организма может быть опасно для здоровья и жизни.  Сайт предусматривает категорию пользователей старше 21 года. Если вы не достигли этого возраста, пожалуйста покиньте сайт!
          Нажимая кнопку «Войти», вы подтверждаете, что достигли 21 летнего возраста и осознаете, что любые последствия Ваших действий являются Вашей ответственностью. 
        </div>
        <button onClick={this.hideDisclaimer} className='button accept'>Войти</button>
      </div>
    );
  }
}

