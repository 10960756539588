import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalItems: 0,
      prices: [],
      types: [],
      ids: [],
      order: [],
      product: {
        id: 0,
        name: "",
        name_en: "",
        price: "",
        description: "",
        description_en: "",
        urls: [],
      },
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount(id = this.props.match.params.id) {
    axios
      .get("https://amanita-shop.ru/item.php", { params: { id: id } })
      //   .get("http://localhost/old_item.php", { params: { id: id } })
      .then(({ data }) => {
        this.setState({
          product: data,
          totalItems: data.totalItems,
          prices: [...data.prices],
          types: [...data.types],
          ids: [...data.ids],
          orderId: this.props.match.params.id,
        });
      });
  }

  nav_arrow_next = () => {
    let id = +this.state.product.id + 1;
    if (this.state.totalItems < id) {
      id = 1;
    }
    this.setState(() => {
      this.state.order = {};
    });
    this.props.history.push(`/item/${id}`);
    this.componentDidMount(id);
    document.querySelector(".swiper-pagination-bullet").click();
  };

  nav_arrow_prev = () => {
    let id = +this.state.product.id - 1;
    if (id < 1) {
      id = this.state.totalItems;
    }
    this.setState(() => {
      this.state.order = {};
    });
    this.props.history.push(`/item/${id}`);
    this.componentDidMount(id);
    document.querySelector(".swiper-pagination-bullet").click();
  };

  showPrices = () => {
    let priceButton;
    let content = [];
    let { ids, prices, types } = this.state;

    if (+prices[0] <= 0) {
      content = [];
      content.push(
        <div className={priceButton}>
          <h3>К сожалению на данный момент весь товар продан. </h3>
        </div>
      );
      return content;
    }

    for (let i = 0; i < prices.length; i++) {
      priceButton = "prices_row button add_to_cart";
      let checkedId = this.props.allOrders.findIndex((el) => {
        return ids[i] == el.id;
      });
      if (checkedId >= 0) {
        priceButton += " added_to_cart";
      }

      content.push(
        <div
          id={ids[i]}
          key={ids[i]}
          className={priceButton}
          onClick={(e) => {
            this.addToCart(
              e,
              this.state.product.name,
              ids[i],
              types[i],
              prices[i]
            );
          }}>
          <div>{types[i]}</div>
          <div>{prices[i]} руб.</div>
        </div>
      );
    }
    return content;
  };

  addToCart = (e, name, itemId, itemType, itemPrice) => {
    let oldOrder = this.props.allOrders;
    let target;
    let item = {
      id: itemId,
      name: name,
      type: itemType,
      price: itemPrice,
    };

    if (e.target.classList.contains("prices_row")) {
      target = e.target;
    } else {
      target = e.target.parentNode;
    }
    target.classList.toggle("added_to_cart");

    let objInArr = oldOrder.findIndex((el) => {
      return +el.id == +itemId;
    });

    if (objInArr >= 0) {
      oldOrder.splice(objInArr, 1);
    } else {
      oldOrder.push(item);
    }

    this.setState(() => {
      this.state.order = oldOrder;
    });

    this.props.addToOrder(oldOrder);
  };

  render() {
    const { id, name, description, urls } = this.state.product;
    return (
      <section className="content__section">
        <div className="item_content__wrapper">
          <div className="item_wrapper">
            <h3>{name.replace("<br>", " ")}</h3>
            <Swiper
              className="sliderSwiper"
              ref={this.sliderRef}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              observer={true}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}>
              {urls.map((item, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <img
                      className="sliderImg"
                      src={"../images/gallery/" + item.img_src}></img>
                  </SwiperSlide>
                );
              })}
              {/* <SwiperSlide key={`youtube${id}`}>
                                <iframe className='youtubeVideo' width="100%" height="auto" src={this.state.product.youtube}
                                        title="YouTube video player" frameBorder="0"
                                        allowFullScreen>
                                </iframe>
                            </SwiperSlide> */}
            </Swiper>
            <div className="description">{description}</div>
          </div>
          <div className="item_price">
            <this.showPrices />
          </div>
        </div>
      </section>
    );
  }
}

export default Item;
