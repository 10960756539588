import React from "react";
import "./delivery.css";

const Delivery = () => {
  return (
    <section className="content__section show-3">
      <div className="content__wrapper">
        <h3>Условия доставки и оплаты</h3>
        <p>
          Осуществляем доставку Почтой России и Boxberry по России, Странам СНГ
          и всему миру.
        </p>
        <p>
          После оформления предоставим Вам трек-номер и иную информацию об
          отправке посредством любых мессенджеров.
        </p>
        <p>
          Возможность доставки в другие страны и способы оплаты уточняйте в
          нашем <a href="https://t.me/muhomori">телеграм - чате.</a>
        </p>
        <p>
          Стоимость доставки можно рассчитать{" "}
          <a target="_blank" href="https://www.pochta.ru/parcels">
            здесь.
          </a>
        </p>
      </div>
    </section>
  );
};

export default Delivery;
