import React from 'react'
import './about.scss'

const AboutPage = () => {
    return (
        <section className="content__section">
            <div className="content__wrapper">
                <video src='./about.mp4' controls></video>
            </div>
        </section>
    )
}

export default AboutPage;